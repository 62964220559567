import { Card, CardBody, CardHeader } from "reactstrap";
import "./index.css";

const PipelineOverviewDone = ({ amount }) => {
  return (
    <Card className="bg-secondary">
      <CardHeader className="bg-white border-0 custom-card-header">
        Finalizados
      </CardHeader>
      <CardBody className="position-relative">
        <dl className="grid grid-cols-2 gap-y-2 default-font-size">
          <dt className="font-semibold">Total</dt>
          <dd className="mb-3 item-description">{amount}</dd>
        </dl>
      </CardBody>
    </Card>
  );
};
export default PipelineOverviewDone;
