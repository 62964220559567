import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Badge, Card, CardBody, CardHeader, Tooltip } from "reactstrap";
import { toast } from "react-toastify";
import "./index.css";
import api from "service/api";
import Loading from "components/Loading";
const PipelineOverviewTranscription = () => {
  const { id } = useParams();
  const [transcription, setTranscription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tooltipOpenReceived, setTooltipOpenReceived] = useState(false);
  const [tooltipOpenProcessing, setTooltipOpenProcessing] = useState(false);
  const [tooltipOpenSucessfull, setTooltipOpenSucessfull] = useState(false);
  const [tooltipOpenErros, setTooltipOpenErros] = useState(false);
  const toggleTooltipReceived = () =>
    setTooltipOpenReceived(!tooltipOpenReceived);
  const toggleTooltipProcessing = () =>
    setTooltipOpenProcessing(!tooltipOpenProcessing);
  const toggleTooltipSucessfull = () =>
    setTooltipOpenSucessfull(!tooltipOpenSucessfull);
  const toggleTooltipErros = () => setTooltipOpenErros(!tooltipOpenErros);

  const fetchPipelineTranscription = useCallback(async () => {
    setLoading(true);
    api
      .get(`/pipeline/${id}/transcription-stats`)
      .then((response) => response.data)
      .then((response) => {
        setTranscription(response);
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          "Houve um problema ao tentar recuperar os status de transcrições do pipeline.",
        );
        return;
      });
    setLoading(false);
  }, [id]);
  useState(() => {
    fetchPipelineTranscription();
  }, [id]);
  return (
    <Card className="bg-secondary">
      <CardHeader className="bg-white border-0 custom-card-header">
        Transcrição
      </CardHeader>
      <CardBody className="position-relative">
        {loading ? <Loading /> : ""}
        <dl className="grid grid-cols-2 gap-y-2 default-font-size">
          <dt className="font-semibold">
            Total recebido{" "}
            <i className="fas fa-info-circle" id="libinforeceived" />
            <Tooltip
              placement="right"
              isOpen={tooltipOpenReceived}
              target="libinforeceived"
              toggle={toggleTooltipReceived}
            >
              <div style={{ margin: "0", padding: "0" }}>
                Número total de áudios recebidos na etapa de coleta. Esses
                áudios serão enviados ao provedor de transcrição e classificados
                nos informativos abaixo como: em progresso, transcritos com
                sucesso ou com falha.
              </div>
            </Tooltip>
          </dt>
          <dd className="mb-3 item-description">
            {transcription?.received ?? "N/A"}
          </dd>
          <dt className="font-semibold">
            Em progresso{" "}
            <i className="fas fa-info-circle" id="libinfoprocessing" />
            <Tooltip
              placement="right"
              isOpen={tooltipOpenProcessing}
              target="libinfoprocessing"
              toggle={toggleTooltipProcessing}
            >
              <div style={{ margin: "0", padding: "0" }}>
                Áudios que estão em processamento com o provedor de transcrição
                ou na fila para serem enviados.
              </div>
            </Tooltip>
          </dt>
          <dd className="mb-3 item-description">
            {transcription?.processing ?? "N/A"}
          </dd>
          <dt className="font-semibold">
            Transcrições com sucesso{" "}
            <i className="fas fa-info-circle" id="libinfosucessfull" />
            <Tooltip
              placement="right"
              isOpen={tooltipOpenSucessfull}
              target="libinfosucessfull"
              toggle={toggleTooltipSucessfull}
            >
              <div style={{ margin: "0", padding: "0" }}>
                Áudios que foram processados e transcritos corretamente pelo
                provedor.
              </div>
            </Tooltip>
          </dt>
          <dd className="mb-3 item-description">
            {transcription?.sucessfull ?? "N/A"}
          </dd>
          <dt className="font-semibold">
            Transcrições com erro{" "}
            <i className="fas fa-info-circle" id="libinfoerror" />
            <Tooltip
              placement="right"
              isOpen={tooltipOpenErros}
              target="libinfoerror"
              toggle={toggleTooltipErros}
            >
              <div style={{ margin: "0", padding: "0" }}>
                Áudios que não puderam ser transcritos devido a falhas no
                provedor ou arquivo.
              </div>
            </Tooltip>
          </dt>
          <dd className="mb-3 item-description">
            {transcription?.errors ?? "N/A"}
          </dd>
        </dl>
      </CardBody>
    </Card>
  );
};
export default PipelineOverviewTranscription;
