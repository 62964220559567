import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Col, Button, FormGroup, Tooltip } from "reactstrap";

const Modals = ({ toggle, setToggle, onSave, option }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm();
  const [hasErrorsCheckbox, setHasErrorsCheckbox] = useState(false);
  const [tooltipOpenSimilarity, setTooltipOpenSimilarity] = useState(false);
  const toggleTooltipSimilarity = () =>
    setTooltipOpenSimilarity(!tooltipOpenSimilarity);

  useEffect(() => {
    if (!option) return;

    if (option.consultant_message !== undefined) {
      setValue("consultant_message", option.consultant_message);
    }
    if (option.customer_message !== undefined) {
      setValue("customer_message", option.customer_message);
    }
    if (option.search_type) {
      setValue("search_type", option.search_type);
    }
  }, [option, setValue]);

  const submit = useCallback((data) => {
    onSave(data);
    setToggle(false);
  }, []);

  const validateCheckboxes = useCallback(
    (value) => {
      if (value) {
        setHasErrorsCheckbox(false);
        return value;
      }
      const { consultant_message, customer_message } = getValues();

      if (!consultant_message && !customer_message) {
        setHasErrorsCheckbox(true);
        return "Selecione pelo menos uma opção.";
      }

      setHasErrorsCheckbox(false);
      return true;
    },
    [getValues],
  );

  return (
    <Modal
      className="modal-dialog modal-xl"
      isOpen={toggle}
      toggle={() => setToggle((v) => !v)}
    >
      <div className="modal-header">
        <div className="modal-title">
          <h3>Configurar biblioteca [ {option?.label ?? "N/A"} ]</h3>
        </div>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setToggle((v) => !v)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="pl-12">
          <Col className="pl-12">
            <h3>Enquadramento de bibliotecas</h3>
            <FormGroup>
              <label className="form-control-label" htmlFor="queues">
                Gerar enquadramento com base nas mensagens do:
              </label>
              <div className="form-check">
                <input
                  {...register("consultant_message", {
                    validate: validateCheckboxes,
                  })}
                  className="form-check-input"
                  type="checkbox"
                  id="consultant_message"
                />
                <label
                  className="form-check-label"
                  htmlFor="consultant_message"
                  style={{ fontSize: "0.85em" }}
                >
                  Consultor
                </label>
              </div>

              <div className="form-check">
                <input
                  {...register("customer_message", {
                    validate: validateCheckboxes,
                  })}
                  className="form-check-input"
                  type="checkbox"
                  id="customer_message"
                />
                <label
                  className="form-check-label"
                  htmlFor="customer_message"
                  style={{ fontSize: "0.85em" }}
                >
                  Cliente
                </label>
              </div>

              {hasErrorsCheckbox ? (
                <span className="text-danger" style={{ fontSize: "0.8em" }}>
                  Selecione pelo menos uma das opções.
                </span>
              ) : null}
            </FormGroup>
          </Col>
        </div>

        <div className="pl-12">
          <Col className="pl-12">
            <h3>Tipo de enquadramento</h3>
            <FormGroup>
              <div className="form-check">
                <input
                  {...register("search_type", {
                    required: "Por favor, selecione uma opção.",
                  })}
                  className="form-check-input"
                  type="radio"
                  id="exact_match"
                  value="EXACT_MATCH"
                />
                <label
                  className="form-check-label"
                  htmlFor="exact_match"
                  style={{ fontSize: "0.8em" }}
                >
                  Exatidão
                </label>
              </div>

              <div className="form-check">
                <input
                  {...register("search_type", {
                    required: "Por favor, selecione uma opção.",
                  })}
                  className="form-check-input"
                  type="radio"
                  id="similarity"
                  value="SIMILARITY"
                />
                <label
                  className="form-check-label"
                  htmlFor="similarity"
                  style={{ fontSize: "0.8em" }}
                >
                  Similaridade{" "}
                  <i className="fas fa-info-circle" id="similarity-label" />
                  <Tooltip
                    placement="right"
                    isOpen={tooltipOpenSimilarity}
                    target="similarity-label"
                    toggle={toggleTooltipSimilarity}
                  >
                    <div style={{ margin: "0", padding: "0" }}>
                      Se a semelhança for maior que{" "}
                      {process.env.REACT_APP_SIMILARITY_THRESHOLD ?? "70"}%,
                      consideramos uma correspondência válida.
                    </div>
                  </Tooltip>
                </label>
              </div>

              <div className="form-check">
                <input
                  {...register("search_type", {
                    required: "Por favor, selecione uma opção.",
                  })}
                  className="form-check-input"
                  type="radio"
                  id="location"
                  value="LOCATION"
                />
                <label
                  className="form-check-label"
                  htmlFor="location"
                  style={{ fontSize: "0.8em" }}
                >
                  Localização
                </label>
              </div>

              {/* Ja deixar aqui para usar no futuro */}

              {/* <div className="form-check">
                <input
                  {...register("search_type", {
                    required: "Por favor, selecione uma opção.",
                  })}
                  className="form-check-input"
                  type="radio"
                  id="proximity"
                  value="PROXIMITY"
                />
                <label
                  className="form-check-label"
                  htmlFor="proximity"
                  style={{ fontSize: "0.8em" }}
                >
                  Proximidade{" "}
                  <i className="fas fa-info-circle" id="proximity-label" />
                  <Tooltip
                    placement="right"
                    isOpen={tooltipOpenProximity}
                    target="proximity-label"
                    toggle={toggleTooltipProximity}
                  >
                    <div style={{ margin: "0", padding: "0" }}>
                      Verificamos uma distância de 100 caracteres entre as palavras cadastradas.
                    </div>
                  </Tooltip>
                </label>
              </div> */}

              {errors.search_type && (
                <span className="text-danger" style={{ fontSize: "0.8em" }}>
                  {errors.search_type.message}
                </span>
              )}
            </FormGroup>
          </Col>
        </div>

        <hr className="my-4" />
        <Button type="button" color="primary" onClick={handleSubmit(submit)}>
          Salvar
        </Button>
      </div>
    </Modal>
  );
};

export default Modals;
