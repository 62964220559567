import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Modal, Button, FormGroup } from "reactstrap";
import qualityApi from "service/qualityApi";

const ModalAttendanceReview = ({
  title,
  disabled,
  attendanceId,
  setUpdateAttendance,
  updateAttendance,
}) => {
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);

  const createAttendanceReview = () => {
    qualityApi
      .post(`/attendance-review`, {
        attendanceId,
      })
      .then(() => {
        toast.success("Review aplicado com sucesso!");
        setToggle(false);
        setUpdateAttendance(!updateAttendance);
      })
      .catch((e) => {
        if (e.response.data.message == "Attendance already reviewed.") {
          toast.error("Esse atendimento já foi revisado!");
        } else {
          toast.error("Erro ao aplicar revisão!");
        }
      });
  };

  return (
    <div>
      <Button
        className="Button ml-1"
        size="sm"
        color={disabled ? "success" : "primary"}
        onClick={() => setToggle(true)}
        disabled={disabled}
      >
        {title}
      </Button>
      <Modal
        className="modal-dialog modal-md"
        isOpen={toggle}
        toggle={() => setToggle((v) => !v)}
      >
        <div className="modal-header">
          <p>Você confirma que a revisão deste atendimento foi concluída?</p>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setToggle((v) => !v)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <div className="pl-lg-2">
            {loading ? (
              <div className="text-center">
                <i className="fa fa-spinner fa-spin fa-2x"></i>
              </div>
            ) : (
              <FormGroup>
                <br />
                <Button
                  type="submit"
                  color="error"
                  onClick={() => setToggle((v) => !v)}
                >
                  Cancelar
                </Button>

                <Button
                  type="submit"
                  color="success"
                  onClick={createAttendanceReview}
                >
                  Confirmar e salvar
                </Button>
              </FormGroup>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAttendanceReview;
