import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Badge, Card, CardBody, CardHeader } from "reactstrap";
import { toast } from "react-toastify";

import "./index.css";

import api from "service/api";

import Loading from "components/Loading";

const PipelineOverviewCollection = () => {
  const { id } = useParams();
  const [collection, setCollection] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchPipelineCollection = useCallback(async () => {
    setLoading(true);
    api
      .get(`/pipeline/${id}/collection`)
      .then((response) => response.data)
      .then((response) => {
        setCollection(response);
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          "Houve um problema ao tentar recuperar os dados de coleta da pipeline.",
        );
        return;
      });
    setLoading(false);
  }, [id]);

  useState(() => {
    fetchPipelineCollection();
  }, [id]);
  return (
    <Card className="bg-secondary">
      <CardHeader className="bg-white border-0 custom-card-header">
        Coleta
      </CardHeader>
      <CardBody className="position-relative">
        {loading ? <Loading /> : ""}
        <dl className="grid grid-cols-2 gap-y-2 default-font-size">
          <dt className="font-semibold">Eventos coletados</dt>
          <dd className="mb-3 item-description">
            {collection?.collected ?? "N/A"}
          </dd>
          <dt className="font-semibold">Áudios coletados</dt>
          <dd className="mb-3 item-description">
            {collection?.audios ?? "N/A"}
          </dd>
        </dl>
      </CardBody>
    </Card>
  );
};

export default PipelineOverviewCollection;
