import React, { useCallback, useEffect, useRef, useState } from "react";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { useFormContext } from "react-hook-form";

import clsx from "clsx";
import { animated, useSpring } from "@react-spring/web";
import { styled } from "@mui/material/styles";
import api from "service/api";
import { toast } from "react-toastify";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2";
import {
  TreeItem2IconContainer,
  TreeItem2Label,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";
import { TreeItem2DragAndDropOverlay } from "@mui/x-tree-view/TreeItem2DragAndDropOverlay";
import { useTreeViewApiRef } from "@mui/x-tree-view";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FolderRounded from "@mui/icons-material/FolderRounded";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { CustomTreeItemContent, StyledTreeItemRoot } from "./style";

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
}

const StyledTreeItemLabelText = styled(Typography)({
  color: "inherit",
  fontWeight: 500,
});

function CustomLabel({ icon: Icon, expandable, children, ...other }) {
  return (
    <TreeItem2Label
      {...other}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {Icon && (
        <Box
          component={Icon}
          className="labelIcon"
          color="inherit"
          sx={{ mr: 1, fontSize: "1.2rem" }}
        />
      )}

      <StyledTreeItemLabelText variant="body2">
        {children}
      </StyledTreeItemLabelText>
    </TreeItem2Label>
  );
}

const isExpandable = (reactChildren) => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isExpandable);
  }
  return Boolean(reactChildren);
};

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getLabelProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const item = publicAPI.getItem(itemId);
  const expandable = isExpandable(children);
  let icon;
  if (expandable) {
    icon = FolderRounded;
  } else if (item.fileType) {
    icon = FolderOpenIcon;
  }

  return (
    <TreeItem2Provider itemId={itemId}>
      <StyledTreeItemRoot {...getRootProps(other)}>
        <CustomTreeItemContent
          {...getContentProps({
            className: clsx("content", {
              "Mui-expanded": status.expanded,
              "Mui-selected": status.selected,
              "Mui-focused": status.focused,
              "Mui-disabled": status.disabled,
            }),
          })}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <CustomLabel
            {...getLabelProps({
              icon,
              expandable: expandable && status.expanded,
            })}
          />
          <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
        </CustomTreeItemContent>
        {children && <TransitionComponent {...getGroupTransitionProps()} />}
      </StyledTreeItemRoot>
    </TreeItem2Provider>
  );
});

export default function LazyAudioFolderTree({ isMulti = false }) {
  const { setValue, getValues } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [cachedFolders, setCachedFolders] = useState(new Map());
  const [data, setData] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const apiRef = useTreeViewApiRef();
  const containerRef = useRef();
  const [scrollTop, setScrollTop] = useState(0);

  const fetchRootFolders = useCallback(
    async (cached) => {
      setLoading(true);
      try {
        const response = await api.get(`/event-audios/folders`);

        if (response.status !== 200) {
          toast.error(
            "Houve um problema ao tentar recuperar os diretórios raízes.",
          );
          return;
        }
        const fetchedFolders = response.data.map((item) => ({
          id: item.id,
          label: item.name,
          children: [],
          fileType: "folder",
        }));

        const updatedData = expandCachedItems(
          fetchedFolders,
          expandedItems,
          cached,
        );
        setData(updatedData);
      } catch (e) {
        console.log(e);
        toast.error(
          "Houve um problema ao tentar recuperar os diretórios raízes.",
        );
        return;
      } finally {
        setLoading(false);
      }
    },
    [expandedItems],
  );

  useEffect(() => {
    setCachedFolders(getValues("CachedFolders") ?? new Map());
    setExpandedItems(getValues("CachedExpandedItems") ?? []);
    setSelectedFolders(getValues("CachedSelectedItems") ?? []);
    fetchRootFolders(getValues("CachedFolders") ?? new Map());
    setValue("folders", getValues("CachedSelectedItems") ?? []);

    const handleScroll = () => {
      if (containerRef.current) {
        setScrollTop(containerRef.current.scrollTop);
      }
    };

    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    setValue("CachedFolders", cachedFolders);
  }, [cachedFolders]);
  useEffect(() => {
    setValue("CachedExpandedItems", expandedItems);
  }, [expandedItems]);
  useEffect(() => {
    setValue("CachedSelectedItems", selectedFolders);
  }, [selectedFolders]);
  useEffect(() => {
    if (containerRef.current) {
      if (loading) {
        containerRef.current.style.overflow = "hidden";
      } else {
        containerRef.current.style.overflow = "auto";
      }
    }
  }, [loading]);

  const expandCachedItems = useCallback(
    (nodes, items, cached) => {
      return nodes.map((node) => {
        if (items.includes(node.id)) {
          node.expanded = true;
        }

        if (cached.has(node.id)) {
          node.children = cached.get(node.id);
          node.children = expandCachedItems(node.children, items, cached);
        }

        return node;
      });
    },
    [cachedFolders],
  );

  const fetchFolders = useCallback(
    async (id) => {
      if (cachedFolders.has(id)) {
        setValue("folders", [...new Set([...selectedFolders, id])]);
        setSelectedFolders((prev) => [...prev, id]);
        return;
      }

      setLoading(true);

      try {
        const response = await api.get(`/event-audios/folders?folderId=${id}`);

        if (response.status !== 200) {
          toast.error("Houve um problema ao tentar recuperar os diretórios");
          return;
        }

        if (!response.data.length) {
          setSelectedFolders((prev) => [...prev, id]);
          setValue("folders", [...new Set([...selectedFolders, id])]);
          cachedFolders.set(id, []);
          return;
        }
        const fetchedFolders = response.data.map((item) => ({
          id: item.id,
          label: item.name,
          children: [],
          fileType: "folder",
        }));

        setData((prev) => updateChildren(prev, id, fetchedFolders));
        cachedFolders.set(id, fetchedFolders);
        setCachedFolders(new Map(cachedFolders));
      } catch (e) {
        console.log(e);
        toast.error("Houve um problema ao tentar recuperar os diretórios");
        return;
      } finally {
        setLoading(false);
      }
    },
    [cachedFolders, selectedFolders, apiRef],
  );

  const updateChildren = (nodes, parentId, children) => {
    return nodes.map((node) => {
      if (node.id === parentId) {
        return { ...node, children };
      }
      if (node.children) {
        return {
          ...node,
          children: updateChildren(node.children, parentId, children),
        };
      }
      return node;
    });
  };

  const handleNodeSelect = useCallback(
    (_, id) => {
      apiRef.current.focusItem(_, null);
      document.activeElement?.blur();

      if (selectedFolders.includes(id)) {
        const updatedSelectedFolders = selectedFolders.filter(
          (selectedId) => selectedId !== id,
        );
        setSelectedFolders(updatedSelectedFolders);
        setValue("folders", updatedSelectedFolders);
        setExpandedItems((prev) => prev.filter((v) => v != id));
        return;
      }
      if (expandedItems.includes(id)) {
        setExpandedItems((prev) => prev.filter((item) => item !== id));
        return;
      }
      if (cachedFolders.has(id)) {
        const cachedData = cachedFolders.get(id);
        if (cachedData.length > 0) {
          setData((prev) => updateChildren(prev, id, cachedData));
          setExpandedItems((prev) => [...new Set([...prev, id])]);
          return;
        }
      }
      fetchFolders(id);
      setExpandedItems((prev) => [...new Set([...prev, id])]);
    },
    [
      selectedFolders,
      cachedFolders,
      expandedItems,
      setValue,
      fetchFolders,
      apiRef,
    ],
  );

  return (
    <div
      ref={containerRef}
      className="styled-scroll"
      style={{
        position: "relative",
        height: "340px",
        overflowY: "scroll",
      }}
    >
      <RichTreeView
        apiRef={apiRef}
        items={data}
        expandedItems={expandedItems}
        selectedItems={selectedFolders}
        onItemClick={handleNodeSelect}
        multiSelect={isMulti}
        sx={{
          height: "fit-content",
          flexGrow: 1,
          maxWidth: 400,
          overflowY: "auto",
        }}
        slots={{ item: CustomTreeItem }}
      />
      <Backdrop
        open={loading}
        sx={{
          color: "#404040",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "absolute",
          top: `${scrollTop}px`,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
