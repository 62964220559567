import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Badge, Card, CardBody, CardHeader } from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment";

import "./index.css";

import api from "service/api";

import Loading from "components/Loading";

import LibrarySearchType from "enum/library-search-type";
import PipelineStatusType from "enum/pipeline-status";

const getLibrarySearchTypeDescription = (search) => {
  switch (search) {
    case LibrarySearchType.EXACT_MATCH:
      return "Pesquisa exata";
    case LibrarySearchType.SIMILARITY:
      return "Similaridade";
    case LibrarySearchType.LOCATION:
      return "Localização";
    case LibrarySearchType.PROXIMITY:
      return "Proximidade";
    default:
      "N/A";
  }
};

const getExecutionStatusDescription = (status) => {
  switch (status) {
    case PipelineStatusType.IDLE:
      return "Não executada";
    case PipelineStatusType.STARTED:
      return "Iniciada";
    case PipelineStatusType.IN_PROGRESS:
      return "Em progresso";
    case PipelineStatusType.COLLECTING:
      return "Coletando eventos";
    case PipelineStatusType.ERROR:
      return "Erro";
    default:
      "N/A";
  }
};

const PipelineOverviewSummary = ({
  setPipelineExecuted,
  pipelineExecution,
}) => {
  const { id } = useParams();
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (summary?.status === "IDLE") {
      setPipelineExecuted(false);
      return;
    }
    setPipelineExecuted(true);
  }, [summary]);

  const fetchPipelineSummary = useCallback(async () => {
    setLoading(true);
    api
      .get(`/pipeline/${id}/summary`)
      .then((response) => response.data)
      .then((response) => {
        setSummary(response);
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          "Houve um problema ao tentar recuperar o sumário da pipeline.",
        );
        return;
      });
    setLoading(false);
  }, [id]);

  useState(() => {
    fetchPipelineSummary();
  }, [id]);

  const getFormattedDate = useCallback(() => {
    if (!summary?.started_at && !pipelineExecution?.started_at) return "N/A";
    return moment(pipelineExecution?.started_at ?? summary?.started_at).format(
      "DD/MM/YYYY HH:mm",
    );
  }, [pipelineExecution, summary]);

  return (
    <Card className="bg-secondary">
      <CardHeader className="bg-white border-0 custom-card-header">
        Sumário
      </CardHeader>
      <CardBody className="position-relative">
        {loading ? <Loading /> : ""}
        <dl className="grid grid-cols-2 gap-y-2 default-font-size">
          Status da execução -{" "}
          <strong>
            {getExecutionStatusDescription(
              pipelineExecution?.status ?? summary?.status,
            )}
          </strong>{" "}
          {pipelineExecution?.status === "STARTED" ||
          summary?.status !== "IDLE" ? (
            <label>
              / Registrado em - <strong>{getFormattedDate()}</strong>
            </label>
          ) : (
            ""
          )}
          <hr className="mt-3 mb-3" />
          <dt className="font-semibold">Título</dt>
          <dd className="mb-3 item-description">{summary?.title ?? "N/A"}</dd>
          <dt className="font-semibold">Descrição</dt>
          <dd className="mb-3 item-description">
            {summary?.description ?? "N/A"}
          </dd>
          <dt className="font-semibold">Cliente</dt>
          <dd className="mb-3 item-description">{summary?.client ?? "N/A"}</dd>
          <dt className="font-semibold">Diretórios</dt>
          <div className="w-100 d-flex flex-wrap gap-3 justify-content-start align-items-start">
            {summary?.folders ? (
              summary?.folders?.map((folder, i) => {
                return (
                  <Badge key={i} color="light" className="custom-badge">
                    / {folder}
                  </Badge>
                );
              })
            ) : (
              <dd className="item-description">N/A</dd>
            )}
          </div>
          <dt className="font-semibold mt-3">Bibliotecas</dt>
          <dd>
            <div className="w-100 d-flex flex-wrap gap-3 justify-content-start align-items-start">
              {summary?.libraries ? (
                summary.libraries.map((library, i) => (
                  <Card
                    key={i}
                    className="border border-gray-300 rounded-lg shadow-sm col-12 col-md-6 col-lg-3"
                    style={{
                      flex: "1 1 auto",
                    }}
                  >
                    <CardHeader className="bg-white border-0 text-center custom-card-header">
                      [ {library?.title} ]
                    </CardHeader>
                    <CardBody className="custom-card-body">
                      <dd className="default-font-size">
                        <dl className="grid grid-cols-2 gap-y-1">
                          <dt className="font-semibold">
                            Mensagem do Consultor
                          </dt>
                          <dd>{library?.consultant_message ? "Sim" : "Não"}</dd>

                          <dt className="font-semibold">Mensagem do Cliente</dt>
                          <dd>{library?.customer_message ? "Sim" : "Não"}</dd>

                          <dt className="font-semibold">Tipo de Busca</dt>
                          <dd>
                            {getLibrarySearchTypeDescription(library?.search)}
                          </dd>
                        </dl>
                      </dd>
                    </CardBody>
                  </Card>
                ))
              ) : (
                <dd className="item-description">N/A</dd>
              )}
            </div>
          </dd>
        </dl>
      </CardBody>
    </Card>
  );
};

export default PipelineOverviewSummary;
