import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Tooltip } from "reactstrap";
import { toast } from "react-toastify";
import "./index.css";
import api from "service/api";
import Loading from "components/Loading";

const PipelineOverviewMatching = ({ setFinishedEventsAmount }) => {
  const { id } = useParams();
  const [matching, setMatchingStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tooltipOpenSucessful, setTooltipOpenSucessful] = useState(false);
  const [tooltipOpenFailure, setTooltipOpenFailure] = useState(false);
  const [tooltipOpenNotAMatch, setTooltipOpenNotAMatch] = useState(false);
  const toggleTooltipSucessful = () =>
    setTooltipOpenSucessful(!tooltipOpenSucessful);
  const toggleTooltipFailure = () => setTooltipOpenFailure(!tooltipOpenFailure);
  const toggleTooltipNotAMatch = () =>
    setTooltipOpenNotAMatch(!tooltipOpenNotAMatch);

  const fetchPipelineMatching = useCallback(async () => {
    setLoading(true);
    api
      .get(`/pipeline/${id}/matching-stats`)
      .then((response) => response.data)
      .then((response) => {
        setMatchingStats(response);
        setFinishedEventsAmount(response?.successful ?? 0);
      })
      .catch((error) => {
        console.error(error);
        toast.error(
          "Houve um problema ao tentar recuperar os status de enquadramento do pipeline.",
        );
        return;
      });
    setLoading(false);
  }, [id]);
  useState(() => {
    fetchPipelineMatching();
  }, [id]);
  return (
    <Card className="bg-secondary">
      <CardHeader className="bg-white border-0 custom-card-header">
        Enquadramento
      </CardHeader>
      <CardBody className="position-relative">
        {loading ? <Loading /> : ""}
        <dl className="grid grid-cols-2 gap-y-2 default-font-size">
          <dt className="font-semibold">
            Enquadramentos com sucesso{" "}
            <i className="fas fa-info-circle" id="matching-successful-label" />
            <Tooltip
              placement="right"
              isOpen={tooltipOpenSucessful}
              target="matching-successful-label"
              toggle={toggleTooltipSucessful}
            >
              <div style={{ margin: "0", padding: "0" }}>
                Número total de eventos que se enquadraram em uma ou mais
                bibliotecas.
              </div>
            </Tooltip>
          </dt>
          <dd className="mb-3 item-description">
            {matching?.successful ?? "N/A"}
          </dd>
          <dt className="font-semibold">
            Enquadramentos com erro{" "}
            <i className="fas fa-info-circle" id="matching-error-label" />
            <Tooltip
              placement="right"
              isOpen={tooltipOpenFailure}
              target="matching-error-label"
              toggle={toggleTooltipFailure}
            >
              <div style={{ margin: "0", padding: "0" }}>
                Número total de eventos que apresentaram erros internos durante
                a execução do algoritmo de enquadramento.
              </div>
            </Tooltip>
          </dt>
          <dd className="mb-3 item-description">
            {matching?.failure ?? "N/A"}
          </dd>
          <dt className="font-semibold">
            Não enquadrados{" "}
            <i className="fas fa-info-circle" id="matching-not-a-match-label" />
            <Tooltip
              placement="right"
              isOpen={tooltipOpenNotAMatch}
              target="matching-not-a-match-label"
              toggle={toggleTooltipNotAMatch}
            >
              <div style={{ margin: "0", padding: "0" }}>
                Número total de eventos que não se enquadraram em nenhuma
                biblioteca.
              </div>
            </Tooltip>
          </dt>
          <dd className="mb-3 item-description">
            {matching?.not_a_match ?? "N/A"}
          </dd>
        </dl>
      </CardBody>
    </Card>
  );
};
export default PipelineOverviewMatching;
