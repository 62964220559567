import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import moment from "moment";
import api from "service/api";
import qualityApi from "service/qualityApi";
import Loading from "components/Loading";
import Select from "react-select";
import { DateTool } from "utils/DateTool";
import { DatePicker } from "components/DatePicker";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";

const POLLING_INTERVAL = 15000;
const MAX_RETRIES = 20;
const STATUS_COMPLETED = "COMPLETED";

const AnalyzedEventReport = () => {
  const permissions = localStorage.getItem("permissions");
  const { register } = useForm();
  const [loading, setLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [endDate, setEndDate] = useState(
    moment().subtract(1, "day").startOf("day"),
  );
  const [startDate, setStartDate] = useState(
    moment().subtract(7, "day").startOf("day"),
  );
  const [countEvents, setCountEvents] = useState("");
  const [canDownloadReport, setCanDownloadReport] = useState(false);
  const [reportUrl, setReportUrl] = useState(null);

  const [reportMetadata, setReportMetadata] = useState({
    client: null,
    startDate: null,
    endDate: null,
    query: null,
  });

  const isValidStartDate = (current) => {
    if (!endDate) return true;
    return current.isBefore(moment(endDate), "day");
  };

  const isValidEndDate = (current) => {
    return current.isBefore(moment().startOf("day"));
  };

  useEffect(() => {
    loadClients();
  }, []);

  const loadClients = async () => {
    if (permissions.includes("export:analyzed_event_report_any_client")) {
      try {
        const { data } = await api.get("/clients?page=1&limit=10000");
        const clients = data.clients.map((c) => ({
          label: c.name,
          value: c.id,
        }));
        setClients(clients);
      } catch {
        toast.error("Erro ao carregar clientes.");
      }
    } else {
      setClients([]);
    }
  };

  const handleSubmit = () => {
    setReportUrl(null);
    setReportLoading(false);
    fetchEventsCount();
  };

  const fetchEventsCount = async () => {
    setCountEvents("");
    setCanDownloadReport(false);

    if (!startDate || !endDate) {
      return toast.error("Preencha corretamente as datas.");
    }

    if (
      permissions.includes("export:analyzed_event_report_any_client") &&
      !selectedClient
    ) {
      return toast.info("Selecione um cliente.");
    }

    const formattedStart = startDate.format("YYYY-MM-DDTHH:mm:ss");
    const formattedEnd = endDate.format("YYYY-MM-DDTHH:mm:ss");
    const range = DateTool.calculateMonthsBetween(formattedStart, formattedEnd);

    if (range < 0) {
      return toast.error("Data final não pode ser menor que a inicial.");
    }

    if (range > 1) {
      return toast.info("O período não pode exceder 1 mês.");
    }

    let query = `startDate=${formattedStart}&endDate=${formattedEnd}`;
    if (selectedClient) {
      query = query + `&clientId=${selectedClient.value}`;
    }

    setLoading(true);
    try {
      const { data, status } = await qualityApi.get(
        `/reports/analysis/count?${query}`,
      );
      setLoading(false);
      setReportMetadata({
        client: selectedClient,
        startDate,
        endDate,
        query,
      });

      if (status === 204 || !data?.events) {
        setCountEvents("0");
        return;
      }

      setCountEvents(String(data.events));
      setCanDownloadReport(data.events > 0);
    } catch {
      setLoading(false);
      toast.error("Erro ao buscar eventos.");
    }
  };

  const requestReport = async () => {
    setReportUrl(null);
    setLoading(true);
    try {
      const { data } = await qualityApi.post(
        `/reports/analysis?${reportMetadata.query}`,
        {},
      );
      const { status, url } = data;
      setLoading(false);

      if (url) {
        setReportUrl(url);
        setReportLoading(false);
        toast.success("Relatório gerado com sucesso!");
        downloadReport(url);
      } else {
        setReportLoading(true);
        setTimeout(
          () => pollReportStatus(reportMetadata.query, 0),
          POLLING_INTERVAL,
        );
      }
    } catch {
      setLoading(false);
      toast.error("Erro ao solicitar relatório.");
    }
  };

  const pollReportStatus = async (reportId, retryCount) => {
    try {
      const { data } = await qualityApi.get(`/reports/analysis?${reportId}`);
      const { status, url } = data;

      if (status === STATUS_COMPLETED) {
        setReportUrl(url);
        setReportLoading(false);
        toast.success("Relatório gerado com sucesso!");
        downloadReport(url);
      } else {
        if (retryCount < MAX_RETRIES) {
          setTimeout(
            () => pollReportStatus(reportId, retryCount + 1),
            POLLING_INTERVAL,
          );
        } else {
          setReportLoading(false);
          toast.error("Tempo limite excedido. Tente novamente mais tarde.");
        }
      }
    } catch {
      setReportLoading(false);
      toast.error("Erro ao consultar status do relatório.");
    }
  };

  const downloadReport = (url) => {
    window.open(url || reportUrl, "_blank");
  };

  return (
    <Container className="mt-7" fluid>
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0">
          <h3 className="mb-0">Relatório de eventos analisados</h3>
          <h5 className="mb-0">
            Este relatório contempla apenas os eventos utilizados na análise
            generativa.
          </h5>
        </CardHeader>
        <CardBody style={{ position: "relative" }}>
          {loading && <Loading />}
          <Form>
            <Row>
              {permissions?.includes(
                "export:analyzed_event_report_any_client",
              ) && (
                <Col lg={4}>
                  <FormGroup>
                    <label htmlFor="client">Cliente</label>
                    <Select
                      id="client"
                      classNamePrefix="select"
                      placeholder="Selecione um cliente"
                      options={clients}
                      value={selectedClient}
                      onChange={setSelectedClient}
                      isSearchable
                      isClearable
                    />
                  </FormGroup>
                </Col>
              )}
              <Col lg={3}>
                <FormGroup>
                  <label htmlFor="startDate">Data Inicial</label>
                  <DatePicker
                    id="startDate"
                    value={startDate}
                    onChange={setStartDate}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    isValidDate={isValidStartDate}
                  />
                </FormGroup>
              </Col>
              <Col lg={3}>
                <FormGroup>
                  <label htmlFor="endDate">Data Final</label>
                  <DatePicker
                    id="endDate"
                    value={endDate}
                    onChange={setEndDate}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    isValidDate={isValidEndDate}
                  />
                </FormGroup>
              </Col>
              <Col
                lg={2}
                style={{
                  textAlign: "center",
                  marginTop: "30px",
                }}
              >
                <Button onClick={handleSubmit}>Buscar</Button>
              </Col>
            </Row>

            {countEvents && (
              <Row className="mt-4">
                <Col lg={8}>
                  <h1>{countEvents}</h1>
                  <label>
                    <b> Eventos analisados </b>
                    para o período selecionado&nbsp;
                    {reportMetadata.startDate?.format("DD/MM/YYYY")} a&nbsp;
                    {reportMetadata.endDate?.format("DD/MM/YYYY")}&nbsp;(
                    {reportMetadata.client?.label})
                  </label>
                </Col>

                {canDownloadReport && (
                  <Col lg={4} style={{ textAlign: "right" }}>
                    {!reportLoading && !reportUrl && (
                      <Button
                        style={{
                          marginTop: "30px",
                        }}
                        color="primary"
                        onClick={requestReport}
                      >
                        Download relatório
                      </Button>
                    )}
                    {reportLoading && (
                      <Button
                        style={{
                          marginTop: "30px",
                        }}
                        color="primary"
                        disabled
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                          />
                          Gerando relatório...
                        </div>
                      </Button>
                    )}
                    {reportUrl && (
                      <Button
                        style={{
                          marginTop: "30px",
                        }}
                        color="success"
                        onClick={() => downloadReport(reportUrl)}
                      >
                        Salvar Arquivo
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
            )}
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default AnalyzedEventReport;
